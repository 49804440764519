<template>
  <div class="ele-body">
    <el-card shadow="never">

      <el-form
        :model="where"
        label-width="77px"
        class="ele-form-search"
        @keyup.enter.native="reload"
        @submit.native.prevent>
        <el-row :gutter="15">
          <el-col :md="8" :sm="12">
            <el-form-item label="时间:">
              <el-date-picker
                style="width: 90%;"
                v-model="where.created_at"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <el-form-item label="关键字:">
              <el-input v-model="where.name" clearable placeholder="请输入名称" >

              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <div class="ele-form-actions">
              <el-button
                type="primary"
                @click="reload"
                icon="el-icon-search"
                class="ele-btn-icon">查询
              </el-button>
              <el-button @click="reset">重置</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <ele-pro-table
        ref="table"
        :needPage="false"
        :datasource="url"
        :where="where"
        :columns="columns"
        :response="response"
        :selection.sync="selection"
      >
        <!-- 表头工具栏 -->
        <template slot="toolbar">
          <el-button
            @click="openEdit(null)"
            type="primary"
            icon="el-icon-plus"
            class="ele-btn-icon"
            size="small"
          >添加
          </el-button>
          <el-button
            @click="removeBatch"
            type="danger"
            icon="el-icon-delete"
            class="ele-btn-icon"
            size="small"
          >删除
          </el-button>
        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="{ row }">
          <div v-if="row.role_id !== 1">
            <el-link
              @click="openEdit(row)"
              icon="el-icon-edit"
              type="primary"
              :underline="false"
            >修改
            </el-link>
            <el-popconfirm
              title="确定要删除吗？"
              @confirm="remove(row)"
              class="ele-action"
            >
              <el-link
                slot="reference"
                icon="el-icon-delete"
                type="danger"
                :underline="false"
              >删除
              </el-link>
            </el-popconfirm>
          </div>
        </template>
      </ele-pro-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <stem-edit :visible.sync="showEdit" :openEdit="openEdit" :data="current" @done="reload"/>
  </div>
</template>

<script>
import stemEdit from "./stem-edit";

export default {
  name: "ReadStem",
  components: {stemEdit},
  data() {
    return {
      // 表格数据接口
      url: "/Admin/smsSigns",
      response: {
        statusCode: 200,
        dataName: 'data.data'
      },
      // 表格列配置
      columns: [
        {
          columnKey: "selection",
          type: "selection",
          width: 45,
          align: "center",
          fixed: "left",
        },
        {
          columnKey: "index",
          type: "index",
          width: 45,
          align: "center",
          fixed: "left",
          showOverflowTooltip: true,
        },
        {
          prop: "name",
          label: "name",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "val",
          label: "内容",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "code",
          label: "模板",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "created_at",
          label: "创建时间",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          columnKey: "action",
          label: "操作",
          width: 230,
          align: "center",
          resizable: false,
          slot: "action",
        },
      ],
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 表格搜索条件
      where: {  },
    };
  },
  mounted() {

  },
  methods: {
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({page: 1});
    },
    /* 显示编辑 */
    openEdit(row) {
      //console.log(row)
      this.current = row;
      this.showEdit = !this.showEdit;
    },
    /* 删除 */
    remove(row) {
      const loading = this.$loading({lock: true});
      this.$http
        .delete("/Admin/smsSigns/" + row.id)
        .then((res) => {
          loading.close();
          if (res.data.code === 200) {
            this.$message({type: "success", message: res.data.msg});
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e.message);
        });
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error("请至少选择一条数据");
        return;
      }
      this.$confirm("确定要删除选中的等级吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({lock: true});
          this.$http.delete(
              "/Admin/smsSigns/" +
              this.selection.map((d) => d.id).toString()
            ).then((res) => {
              loading.close();
              if (res.data.code === 200) {
                this.$message({type: "success", message: res.data.msg});
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch((e) => {
              loading.close();
              this.$message.error(e.message);
            });
        }).catch(() => {
        });
    },
    // 重置
    reset() {
      this.where = {};
      this.$nextTick(() => {
        this.reload();
      });
    }
  },
};
</script>

<style scoped>
</style>
