<!-- 用户编辑弹窗 -->
<template>
    <el-dialog
            :title="isUpdate?'修改模板':'添加模板'"
            :visible="visible"
            width="60%"
            :destroy-on-close="true"
            :lock-scroll="false"
            custom-class="ele-dialog-form"
            @update:visible="updateVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <!--      等级-->
            <el-form-item label="名称" prop="name">
                <el-col :span="18">
                    <el-input v-model.number="ruleForm.name" placeholder="请输入分数"></el-input>
                </el-col>
            </el-form-item>
            <!--分数-->
            <el-form-item label="内容" prop="val">
                <el-col :span="18">
                    <el-input v-model="ruleForm.val" placeholder="请输入内容"></el-input>
                </el-col>
            </el-form-item>
            <!-- 编辑器 -->
            <el-form-item label="模版" prop="code">
                <el-col :span="18">
                    <el-input v-model="ruleForm.code" placeholder="请输入模版"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="描述">
                <el-col :span="18">
                    <el-input v-model="ruleForm.content" placeholder="请输入描述" :rows="3" type="textarea"></el-input>
                </el-col>

            </el-form-item>



        </el-form>
        <div slot="footer">
            <el-button
                    @click="resetForm">取消
            </el-button>
            <el-button
                    type="primary"
                    @click="save"
                    :loading="loading">保存
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import TinymceEditor from '@/components/TinymceEditor';
    export default {
        name: 'UserEdit',
        props: {
            // 弹窗是否打开
            visible: Boolean,
            // 修改回显的数据
            data: Object,
          // 父组件方法，关闭弹框
          openEdit: Function
        },
        components: {TinymceEditor},
        data() {
            return {
                // 表单数据
                init: {},
                // 表单验证规则domains
                ruleForm: Object.assign({}, this.data),
                // 等级下拉数据
                gradeSelection: [],
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'}
                    ],
                    code: [
                        {required: true, message: '请输入模板', trigger: 'blur'}
                    ],
                    val: [
                        {required: true, message: '请输入内容', trigger: 'blur'}
                    ],

                },
                // 提交状态
                loading: false,
                // 是否是修改
                isUpdate: false,

            }
        },
        watch: {
            data() {
                if(this.data){
                    this.ruleForm = Object.assign({}, this.data);
                    this.isUpdate = true;
                }else {
                    this.ruleForm = Object.assign({}, this.data);
                    this.isUpdate = false;
                }
            }
        },
        methods: {
            /* 保存编辑 */
            save() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        console.log(this.isUpdate)
                        this.loading = true;
                        this.$http[this.isUpdate ? 'put' : 'post'](this.isUpdate ? '/Admin/smsSigns/' + this.ruleForm.id : '/Admin/smsSigns', this.ruleForm).then(res => {
                            this.loading = false;
                            if (res.data.code === 200) {
                                this.$message({type: 'success', message: res.data.msg});
                                if (!this.isUpdate) {
                                    this.form = {};
                                }
                                this.updateVisible(false);
                                this.$emit('done');
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                    } else {
                        return false;
                    }
                });
            },
          // 关闭
          resetForm(ruleForm){
            // Object.assign(this.$data.ruleForm,this.$options.data().ruleForm)
            // this.$emit('update:visible', false);
            // 调用父组件方法 隐藏弹框
            this.openEdit(null)
          },
            /* 更新visible */
            updateVisible(value) {
                this.$emit('update:visible', value);
            },

        }
    }
</script>

<style scoped>
</style>
